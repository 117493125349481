import { getRequestMetadataForWebViewApp } from '@finn/ua-app';
import { IS_DIRECT_CHECKOUT_ENABLED } from '@finn/ua-constants';
import { Features } from '@finn/ua-featureflags';
import {
  config,
  CookieKeys,
  generateLocalizationHeaders,
  getClientCookie,
  isServer,
  REQUEST_ID_HEADER_NAME,
  traceabilityHeadersBrowser,
} from '@finn/ui-utils';
import axios, { AxiosRequestConfig, Method } from 'axios';
import Cookies from 'js-cookie';
import { parse, stringify } from 'query-string';

import {
  ComingSoonDealCreationBody,
  ConfirmationPayload,
  ContactUpdateBody,
  D2CJson,
  DealCreationBody,
  DealCreationWithAuth,
  DealInfo,
  DirectCheckoutDealCreationBody,
  DirectCheckoutDealCreationResult,
  DirectCheckoutEligibilityBody,
  DirectCheckoutEligibilityResult,
  IntentCreationInfo,
  LeadCreationBody,
  LicenseInfo,
  LocationUnavailableDealCreationBody,
  PaymentInfo,
  ProcessPaymentInfo,
  UpdateDealInfo,
} from '~/types/checkout';
import { getFinnRequestId } from '~/utils/general';

import { axiosErrorHandler } from './axiosUtils';

const getRelatedVehicleId = async ({ locale, vehicleId, term }) => {
  const shouldGetRelatedVehicle =
    !isServer() &&
    Cookies.get(`${locale}_${Features.ExpRelatedConfigs}`) === 'b';

  if (shouldGetRelatedVehicle) {
    try {
      const response: { data: { relatedVehicleId: string } } = await axios.post(
        '/api/getRelatedVehicleId',
        {
          origin: window.location.origin,
          vehicleId,
          term,
        },
        { headers: traceabilityHeadersBrowser(), withCredentials: true }
      );
      const relatedVehicleId = response?.data?.relatedVehicleId as string;

      return relatedVehicleId ?? vehicleId;
    } catch {
      return vehicleId;
    }
  }

  return vehicleId;
};

export type CheckoutRequestOptions = {
  timeout?: number;
  cookie?: string;
  withCredentials?: boolean;
  isProxy?: boolean;
};

export const callCheckoutEndpointPlain = async <T>(
  endpoint: string,
  options: AxiosRequestConfig,
  isProxy = true,
  method: Method = 'GET'
) => {
  options.method = method;
  options.url = `${config.CHECKOUT_API_URL}/${endpoint}`;
  options.headers = {
    ...options.headers,
    'X-Request-Source': 'web',
    'X-Request-ABS': 'enabled',
    [REQUEST_ID_HEADER_NAME]: getFinnRequestId(),
    ...traceabilityHeadersBrowser(),
  };

  if (isProxy && !isServer()) {
    options.url = `${window.origin}/api/checkout/${endpoint}`;
  }

  return axios.request<T>(options);
};

const callCheckoutEndpoint = async <T = any>(
  endpoint: string,
  payload:
    | {}
    | DealCreationBody
    | ContactUpdateBody
    | DealCreationWithAuth
    | ProcessPaymentInfo
    | PaymentInfo
    | DealInfo
    | ConfirmationPayload
    | LicenseInfo
    | IntentCreationInfo
    | DirectCheckoutEligibilityBody
    | DirectCheckoutDealCreationBody
    | D2CJson,
  { timeout, cookie, withCredentials, isProxy }: CheckoutRequestOptions,
  method: Method = 'POST',
  locale: string,
  isE2ETest = false,
  traceabilityHeaders?: Record<string, string>
): Promise<T> => {
  const requestId = getFinnRequestId();
  if (!traceabilityHeaders) {
    if (isServer()) {
      traceabilityHeaders = {};
      console.warn(
        `checkout endpoint: ${endpoint} was called from server without traceability headers`
      );
    } else {
      traceabilityHeaders = traceabilityHeadersBrowser();
    }
  }

  const { appQueryParams, appHeaders } = getRequestMetadataForWebViewApp();
  const options: AxiosRequestConfig = {
    method,
    data: payload,
    timeout,
    url: `${config.CHECKOUT_API_URL}/${endpoint}`,
    params: appQueryParams,
    headers: {
      ...generateLocalizationHeaders(locale),
      ...(isE2ETest ? { 'X-Test-In-Progress': 'yes' } : {}),
      'X-Stripe-Payment-Element': 'enabled',
      'X-Request-Source': 'web',
      'X-Request-ABS': 'enabled',
      [REQUEST_ID_HEADER_NAME]: requestId,
      ...appHeaders,
      ...traceabilityHeaders,
    },
  };

  if (isProxy && !isServer()) {
    const queryObj = parse(window?.location?.search);
    const queryString = stringify({ utm_source: queryObj?.utm_source });
    options.url = `${window.origin}/api/checkout/${endpoint}?${queryString}`;
    if (getClientCookie(CookieKeys.E2E_TEST_IN_PROGRESS) === 'yes') {
      options.headers = { ...options.headers, 'X-Test-In-Progress': 'yes' };
    }
  }

  if (withCredentials) {
    options.withCredentials = true;
  }

  if (cookie) {
    options.withCredentials = true;
    options.headers = {
      Cookie: cookie,
      ...generateLocalizationHeaders(locale),
      ...options.headers,
    };
  }

  try {
    const { data, status } = await axios.request<T>(options);

    return { ...data, status };
  } catch (error) {
    return {
      requestId,
      ...axiosErrorHandler(error),
    } as any;
  }
};

export const CHECKOUT_TIMEOUT_THRESHOLD = 20000;

export const directCheckoutEligibility = async (
  dealInfo: DirectCheckoutEligibilityBody,
  {
    timeout = CHECKOUT_TIMEOUT_THRESHOLD,
    locale,
  }: {
    timeout?: number;
    locale: string;
  }
) =>
  await callCheckoutEndpoint<DirectCheckoutEligibilityResult>(
    'directCheckout/eligibility',
    dealInfo,
    {
      timeout,
      isProxy: true,
      withCredentials: true,
    },
    'POST',
    locale
  );

export const directCheckout = async (
  dealInfo: DirectCheckoutDealCreationBody,
  {
    timeout = CHECKOUT_TIMEOUT_THRESHOLD,
    locale,
  }: {
    timeout?: number;
    locale: string;
  }
) => {
  const finalVehicleId = await getRelatedVehicleId({
    locale,
    vehicleId: dealInfo.vehicleId,
    term: dealInfo.term,
  });

  return await callCheckoutEndpoint<DirectCheckoutDealCreationResult>(
    'directCheckout/create',
    { ...dealInfo, vehicleId: finalVehicleId },
    {
      timeout,
      isProxy: true,
      withCredentials: true,
    },
    'POST',
    locale
  );
};

export const createLead = async (
  contactData: LeadCreationBody,
  {
    timeout = CHECKOUT_TIMEOUT_THRESHOLD,
    locale,
  }: {
    timeout?: number;
    locale: string;
    isComingSoon?: boolean;
  }
) => {
  const isTesting =
    getClientCookie(CookieKeys.DISABLE_DIRECT_CHECKOUT_FOR_E2E) === 'yes';
  const isDirectCheckoutEnabled = isTesting
    ? false
    : IS_DIRECT_CHECKOUT_ENABLED;

  const finalVehicleId = await getRelatedVehicleId({
    locale,
    vehicleId: contactData.vehicleId,
    term: contactData.term,
  });

  return await callCheckoutEndpoint(
    'deal',
    { ...contactData, isDirectCheckoutEnabled, vehicleId: finalVehicleId },
    {
      timeout,
      isProxy: true,
      withCredentials: true,
    },
    'POST',
    locale
  );
};

export const updateContact = async (
  contactData: ContactUpdateBody,
  {
    timeout = CHECKOUT_TIMEOUT_THRESHOLD,
    locale,
  }: {
    timeout?: number;
    locale: string;
    isComingSoon?: boolean;
  }
) =>
  await callCheckoutEndpoint(
    `contact/${contactData.contactId}`,
    contactData,
    {
      timeout,
      isProxy: true,
      withCredentials: true,
    },
    'PATCH',
    locale
  );

export const createUnavailableDeal = async (
  dealInfo: ComingSoonDealCreationBody | LocationUnavailableDealCreationBody,
  {
    timeout = CHECKOUT_TIMEOUT_THRESHOLD,
    locale,
  }: {
    timeout?: number;
    locale: string;
  }
) =>
  await callCheckoutEndpoint(
    'processDealCreationComingSoon',

    dealInfo,
    {
      timeout,
      isProxy: true,
      withCredentials: true,
    },
    'POST',
    locale
  );

export const processAddress = async (
  dealInfo: DealCreationBody,
  {
    timeout = CHECKOUT_TIMEOUT_THRESHOLD,
    locale,
  }: {
    timeout?: number;
    locale: string;
  }
) =>
  await callCheckoutEndpoint(
    'deal/address',
    dealInfo,
    {
      timeout,
      isProxy: true,
      withCredentials: true,
    },
    'POST',
    locale
  );

export const updateDeal = async (
  updateDealInfo: UpdateDealInfo,
  {
    timeout = CHECKOUT_TIMEOUT_THRESHOLD,
    locale,
  }: {
    timeout?: number;
    locale: string;
  }
) =>
  await callCheckoutEndpoint(
    'updateDeal',
    updateDealInfo,
    {
      timeout,
      isProxy: true,
    },
    'POST',
    locale
  );

export const getClientSecret = async (
  intentCreationInfo: IntentCreationInfo,
  { timeout = CHECKOUT_TIMEOUT_THRESHOLD, locale }
) =>
  await callCheckoutEndpoint(
    'createSetupIntent',
    intentCreationInfo,
    { timeout, isProxy: true },
    'POST',
    locale
  );

export const processPayment = async <T>(
  paymentInfo: T,
  {
    timeout = CHECKOUT_TIMEOUT_THRESHOLD,
    locale,
  }: {
    timeout?: number;
    locale: string;
  }
) =>
  await callCheckoutEndpoint(
    'processPayment',
    paymentInfo,
    {
      timeout,
      isProxy: true,
    },
    'POST',
    locale
  );

export const processConfirm = async (
  payload: ConfirmationPayload,
  {
    timeout = CHECKOUT_TIMEOUT_THRESHOLD,
    locale,
  }: {
    timeout?: number;
    locale: string;
  }
) =>
  await callCheckoutEndpoint(
    'confirm',
    payload,
    {
      timeout,
      isProxy: true,
    },
    'POST',
    locale
  );

export const restoreProcess = async (
  dealInfo: DealInfo | {},
  checkoutRequestOptions: CheckoutRequestOptions,
  locale: string,
  isE2ETest = false,
  traceabilityHeaders?: Record<string, string>
) => {
  const data = await callCheckoutEndpoint(
    'restore',
    dealInfo,
    checkoutRequestOptions,
    'POST',
    locale,
    isE2ETest,
    traceabilityHeaders
  );

  return data;
};

export const verifyDriverLicenses = async (
  driverLicenses: LicenseInfo,
  {
    locale,
    timeout = 25000,
  }: {
    timeout?: number;
    locale: string;
  }
) =>
  await callCheckoutEndpoint(
    'verifyDriverLicenses',
    driverLicenses,
    {
      timeout,
      isProxy: true,
    },
    'POST',
    locale
  );

// Get pre-signed urls for file Driver License uploads
export const getLicenseUploadUrl = async (
  identityInfo: {
    contactId: number | string;
    dealId: number | string;
    hash: string;
  },
  file: File,
  nameSuffix: string,
  locale: string
) => {
  const typeOfDestination = 'DL';
  const { name, type } = file;
  const ext = name.substring(name.lastIndexOf('.'));
  const filename = `${identityInfo.contactId}_${identityInfo.dealId}_${identityInfo.hash}_${nameSuffix}${ext}`;
  const url = `${config.CHECKOUT_API_URL}/uploadurl`;
  const { status, data } = await axios.get(url, {
    params: {
      filename,
      filetype: type,
      bucket: typeOfDestination,
    },
    headers: {
      ...generateLocalizationHeaders(locale),
      'X-Request-Source': 'web',
      'X-Request-ABS': 'enabled',
      [REQUEST_ID_HEADER_NAME]: getFinnRequestId(),
      ...traceabilityHeadersBrowser(),
    },
  });

  return { status, data };
};

// Upload files directly to s3 using pre-signed urls
export const uploadFileBySignedUrl = async (
  url: string,
  file: File,
  locale: string
) => {
  const options = {
    headers: {
      'Content-Type': file.type,
      [REQUEST_ID_HEADER_NAME]: getFinnRequestId(),
      ...generateLocalizationHeaders(locale),
      ...traceabilityHeadersBrowser(),
    },
  };

  try {
    const { data, status } = await axios.put(url, file, options);

    return { data, status };
  } catch (err) {
    const statusCode = err.status || err.statusCode || 500;

    return { status: statusCode, data: err };
  }
};
